<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
    </header>
    <!-- 标题 -->
    <div class="title">
      <div class="title_Btn">{{ $t('pay').paymentMethod.toUpperCase() }}</div>
    </div>
    <!-- 表单 -->
    <div class="content">
      <div class="form">
        <!-- 姓名 -->
        <div class="item">
          <div class="item_lable">{{ $t('pay').Name }}</div>
          <van-field v-model="CollectionDetail.CollectionName" placeholder="">
          </van-field>
        </div>
        <!-- 银行名称 -->
        <div class="item">
          <div class="item_lable">{{ $t('pay').BankName }}</div>
          <van-field v-model="CollectionDetail.CollectionBank" placeholder="">
          </van-field>
        </div>
        <!-- 银行账号 -->
        <div class="item">
          <div class="item_lable">{{ $t('pay').BankAccount }}</div>
          <van-field
            v-model="CollectionDetail.CollectionAccount"
            placeholder=""
          >
          </van-field>
        </div>
        <!-- TRC20地址 -->
        <div class="item">
          <div class="item_lable">{{ $t('pay').TRC20Address }}</div>
          <van-field v-model="CollectionDetail.TRC20" placeholder="">
          </van-field>
        </div>
        <!-- 收款码 -->
        <div class="item">
          <div class="item_lable">{{ $t('pay').CollectionQRCode }}</div>
          <div class="item_code">
            <div class="item_up" @click="toShowQRCode">+</div>
            <van-badge
              v-for="(item, index) in CollectionDetail.UserQRCode"
              :key="index"
            >
              <div
                class="item_up"
                @click="toImagePreview(item.CollectionQRCode)"
              >
                <div class="item_img">
                  <img
                    :src="item.CollectionQRCode"
                    width="90px"
                    height="90px"
                  />
                  <div class="item_i_txt">{{ item.Name }}</div>
                </div>
              </div>
              <template #content>
                <van-icon
                  name="cross"
                  class="badge-icon"
                  @click="toDelImg(index)"
                />
              </template>
            </van-badge>
          </div>
        </div>
        <!-- 提交 -->
        <div class="item_btn" @click="handleSub">
          {{ $t('pay').submitInformation.toUpperCase() }}
        </div>
      </div>
    </div>
    <!-- 添加收款码 -->
    <van-dialog
      v-model="showAddQRCode"
      :showConfirmButton="false"
      closeOnClickOverlay
    >
      <div class="dialog_upLock">
        <div class="di_title">{{ $t('pay').AddCollectionQRCode }}</div>
        <div class="di_info">
          <!-- 收款码名称 -->
          <div class="item">
            <div class="item_lable">{{ $t('pay').PlatformName }}</div>
            <van-field v-model="Name" placeholder=""> </van-field>
          </div>
          <!-- 收款码图片 -->
          <div class="item">
            <div class="item_lable">{{ $t('pay').CollectionQRCode }}</div>
            <van-uploader preview-size="100" :after-read="justAfterRead">
              <img
                v-if="CollectionQRCode"
                :src="CollectionQRCode"
                width="100px"
                height="100px"
              />
            </van-uploader>
          </div>
          <div class="di_i_btn" @click="addQRCode">
            {{ $t('pay').ConfirmAdd }}
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { ImagePreview } from 'vant'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showAddQRCode: false,
      CollectionDetail: {},
      Name: '',
      CollectionQRCode: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getMyCollectionDetail()
  },
  methods: {
    // 获取我的收款信息
    async getMyCollectionDetail() {
      const res = await userApi.getMyCollectionDetail()
      this.CollectionDetail = res.Data
    },
    //删除图片
    toDelImg(index) {
      this.CollectionDetail.UserQRCode.splice(index, 1)
    },
    //图片预览
    toImagePreview(val) {
      ImagePreview({images:[val],closeable: true})
    },
    //上传图片回调
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.CollectionQRCode = await userApi.getCaptchaFile(formdata)
    },
    //显示添加收款码
    toShowQRCode() {
      this.Name = ''
      this.CollectionQRCode = ''
      this.showAddQRCode = true
    },
    //添加收款码
    addQRCode() {
      if (!this.Name) return
      if (!this.CollectionQRCode) return
      const form = {
        Name: this.Name,
        CollectionQRCode: this.CollectionQRCode
      }
      this.CollectionDetail.UserQRCode.push(form)
      this.showAddQRCode = false
    },
    //提交
    async handleSub() {
      // if (!this.checkForm()) return
      await userApi.updateCollection(this.CollectionDetail)
      this.$toast(this.$t('common').success)
      this.$router.go(-1)
    },
    //检查表单
    checkForm() {
      if (!this.CollectionDetail.CollectionName) return
      if (!this.CollectionDetail.CollectionAccount) return
      if (!this.CollectionDetail.CollectionBank) return
      if (!this.CollectionDetail.TRC20) return
      if (this.CollectionDetail.UserQRCode.length < 1) return
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  padding-bottom: 30px;
  header {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 291px;
    background-image: url(../../assets/newImg/regedit_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 20px 0 20px;
    .header_right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      right: 20px;
      .txt {
        margin: 0 10px;
      }
      .img {
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }
  .title {
    margin-top: -270px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title_Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 212px;
      height: 40px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      box-shadow: 0px 1px 3px 0px rgba(173, 61, 0, 0.75);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #ffffff;
      line-height: 12px;
    }
  }
  .content {
    margin: -20px 20px 0 20px;
    .form {
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(73, 47, 182, 0.75);
      border-radius: 15px;
      padding: 40px 20px;
      .item_code {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        .badge-icon {
          display: block;
          font-size: 10px;
          line-height: 16px;
        }
      ::v-deep  .van-badge--fixed {
          right: 10px;
        }
        .item_up {
          margin-right: 8px;
          width: 90px;
          height: 90px;
          background: #f3f3f3;
          border-radius: 5px;
          color: #492fb6;
          font-size: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 13px;
          .item_img {
            text-align: center;
            .item_i_txt {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: -23px;
              width: 90px;
              height: 23px;
              background: #492fb6;
              border-radius: 5px;
              border: 1px solid #999999;
              font-family: PingFang SC;
              font-weight: 800;
              font-size: 12px;
              color: #ffffff;
            }
          }
        }
      }

      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }
      .item_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 44px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 22px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #ffffff;
      }
      .select_btn {
        margin-right: 10px;
      }
      .select_country_btn {
        height: 38px;
        border-radius: 19px;
        border: 1px solid #999999;
        padding: 0 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .send_btn {
        margin-right: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 24px;
        background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
        border-radius: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
      }
      .van-button {
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
  }
  ::v-deep .van-field__value {
    overflow: visible;
    display: grid;
  }
  ::v-deep .van-button__content {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }
  .van-cell {
    margin-bottom: 20px;
    height: 38px;
    border-radius: 19px;
    border: 0.5px solid #999999;
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
  }
  ::v-deep .van-field__right-icon {
    margin: -10px 8px;
    padding: 0px;
    line-height: normal;
  }
}
::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}
.dialog_upLock {
  width: 100%;
  .di_title {
    position: relative;
    margin: 0 30px -20px 30px;
    height: 41px;
    background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
    box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
    border-radius: 21px;
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 18px;
    color: #8c3b00;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .di_info {
    height: 325px;
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
    padding-top: 30px;
    .item {
      margin: 15px;
      text-align: left;
      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
    .di_i_btn {
      margin: 15px;
      height: 44px;
      background: linear-gradient(-90deg, #492fb6 0%, #7f49c5 100%);
      border-radius: 22px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ::v-deep .van-uploader__upload-icon {
    color: #492fb6;
    font-size: 24px;
  }
}
</style>
